.slick-dots {
    position: absolute;
    bottom: 80px; /* Adjust this based on where you want the dots to appear */
    left: 50%;
    transform: translateX(-50%);
    display: flex !important;
    justify-content: center;
  }
  
  .slick-dots li button:before {
    font-size: 12px;
    color: white; /* Dot color */
  }
  
  .slick-dots li.slick-active button:before {
    color: teal; /* Active dot color */
  }
  